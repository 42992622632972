import DataGrid, {
    Column,
    Editing,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Button as DataGridButton,
} from 'devextreme-react/data-grid';
import { confirm } from 'devextreme/ui/dialog';
import { useSnackbar } from 'notistack';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import LoadingScreen from '../loading';
import Api from '../../shared/networking/api';
import { useRef } from 'react';

interface MeasurementData {
    id?: number;
    tidsstempel?: string;
    maalerVisning?: string;
}

const EditMeasurementGrid = ({ id }: { id: number }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [data, isLoading, , , , refetch] = useFetch<MeasurementData[]>('/Forsyning/getTidsserieData?id=' + id);
    const gridRef = useRef<DataGrid>(null);

    if (isLoading) {
        return <LoadingScreen />;
    }

    const onSaving = async ({
        changes,
    }: {
        changes: { type: string; key: MeasurementData; data: MeasurementData }[];
    }) => {
        console.log(changes);
        // key is the original data, data is the new data, but only what has changed

        // Handle case where new date is the same as an existing date
        if (changes[0].type === 'insert') {
            const same = data.find(
                (d) =>
                    new Date(d.tidsstempel ?? 0).toLocaleDateString() ==
                    new Date(changes[0].data.tidsstempel ?? 0).toLocaleDateString()
            );
            if (same) {
                const result = await confirm(
                    `Der findes allerede data for ${new Date(same.tidsstempel ?? 0).toLocaleDateString()} med værdi ${
                        same.maalerVisning
                    }. Vil du overskrive den til ${changes[0].data.maalerVisning ?? changes[0].key.maalerVisning}?`,
                    'Er du sikker?'
                );
                if (!result) {
                    return;
                }
                Api.delete('/Forsyning/tidsserieData?id=' + same.id);
                refetch();
            }
        }

        // Overwrite the original data with the new data
        const newTidsstempel = changes[0].data?.tidsstempel;
        const newMaalerVisning = changes[0].data?.maalerVisning;
        const newData = {
            id: changes[0].key.id,
            maalerId: id,
            tidsstempel: newTidsstempel,
            maalerVisning: newMaalerVisning,
        };
        const result = await Api.put('/Forsyning/setTidsserieData', newData);
        if (Api.ok(result)) {
            enqueueSnackbar('Gemt', { variant: 'success' });
        } else {
            enqueueSnackbar('Noget gik galt. Prøv igen', { variant: 'error' });
        }
    };

    const onRowRemove = async (e: { data: MeasurementData }) => {
        Api.delete('/Forsyning/tidsserieData?id=' + e.data.id);
    };

    const handleEditorPreparing = (e: any) => {
        if (e.dataField === 'tidsstempel' && e.parentType === 'dataRow') {
            if (e.row.isNewRow) {
                // Enable editing for new rows
                e.editorOptions.readOnly = false;
            } else {
                // Disable editing for existing rows
                e.editorOptions.readOnly = true;
            }
        }
    };

    return (
        <>
            <div style={{ height: '100%', width: '100%', justifyContent: 'center', display: 'flex' }}>
                <DataGrid
                    ref={gridRef}
                    dataSource={data}
                    rowAlternationEnabled
                    sorting={{ mode: 'single' }}
                    onSaving={onSaving}
                    onRowRemoved={onRowRemove}
                    onEditorPreparing={handleEditorPreparing}
                    // scrolling={{ mode: 'virtual' }}
                    height={'100%'}
                    style={{ maxWidth: '600px' }}
                >
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    {/* <SearchPanel visible={true} /> */}
                    <Editing mode={'cell'} allowAdding={true} allowUpdating={true} allowDeleting={true} />
                    <Column
                        dataField='tidsstempel'
                        defaultSortOrder={'desc'}
                        caption={'Dato'}
                        dataType={'date'}
                        format={'dd-MM-yyyy'}
                        alignment={'right'}
                    />
                    <Column
                        dataField='maalerVisning'
                        caption={'Målervisning'}
                        dataType={'number'}
                        format={'###,##0.0000'}
                        alignment={'right'}
                    />
                    <Column
                        type='buttons'
                        // width={110}
                        visible={true}
                        showInColumnChooser={false}
                    >
                        {/* <DataGridButton
                            icon='edit'
                            text='Rediger'
                            onClick={(e: { row: { rowIndex: number } }) =>
                                gridRef.current?.instance.editRow(e.row.rowIndex)
                            }
                        /> */}
                        <DataGridButton
                            icon='trash'
                            text='Slet'
                            onClick={(e: { row: { rowIndex: number } }) =>
                                gridRef.current?.instance.deleteRow(e.row.rowIndex)
                            }
                        />
                    </Column>
                </DataGrid>
            </div>
        </>
    );
};

export default EditMeasurementGrid;
