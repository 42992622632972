import { CommandRenderKeys } from './render-commands';
import { IFile } from '../file-displaying/common';
import { UploaderOptions } from '../file-management/drag-drop-uploader';
import { isMobile } from 'react-device-detect';
import { z } from 'zod';

export interface RenderedSpecialColumn {
    position: 'before' | 'after';
    /** dxColumn Element */
    element: JSX.Element;
}

export interface IRowData {
    Files?: IFile[];
    id: number;
    [key: string]: unknown;
}

//#region Lookups

export type innerFilterArray = Array<string | number | innerFilterArray>;
export type filterArray = Array<string | innerFilterArray>;

//#endregion Lookups

export const zFormatter = z
    .object({
        /** How many digits to show after the decimal point */
        precision: z.number().optional(),
    })
    .and(
        z
            .object({
                /** Enum of pre-defined format types */
                type: z.enum([
                    'billions',
                    'day',
                    'decimal',
                    'exponential',
                    'fixedPoint',
                    'largeNumber',
                    'longDate',
                    'longTime',
                    'millions',
                    'millisecond',
                    'month',
                    'monthAndDay',
                    'monthAndYear',
                    'percent',
                    'quarter',
                    'quarterAndYear',
                    'shortDate',
                    'shortTime',
                    'thousands',
                    'trillions',
                    'year',
                    'dayOfWeek',
                    'hour',
                    'longDateLongTime',
                    'minute',
                    'second',
                    'shortDateShortTime',
                ]),
            })
            .or(
                z.object({
                    type: z.literal('currency'),

                    /** The currency code to show with the value */
                    currency: z.string(),
                })
            )
    );

export const zLookup = z.object({
    /**
     * The url to fetch the lookup data from. May have to be partial
     *
     * TODO: Correct this comment about partiality
     */
    url: z.string(),

    /** Overwrites the grid's base url for this lookup.  */
    urlOverride: z.string().optional(),

    /** dataFields whose value alter which options to show in the lookup */
    parentDataFields: z.array(z.string()).optional(),

    /** dataFields whose options to show in the lookup is altered by this column */
    childDataFields: z.array(z.string()).optional(),
});

export const zValidationRules = z.object({
    /** Max length for inputs. Not validated on initial data load */
    maxLength: z.number().optional(),

    /**
     * Is the field required?
     *
     * @default false
     */
    requiredRule: z.boolean().default(false),

    /**
     * A custom validation regex string
     */
    patternRule: z
        .object({
            message: z.string(),
            pattern: z.string(),
        })
        .optional(),

    /**
     * Is the field an email?
     *
     * @default false
     */
    emailRule: z.boolean().default(false),
});

export const zColumn = z.object({
    /**
     * Wether the column can be edited
     * @default true
     */
    allowEditing: z.boolean().default(true),

    /**
     * Automatically expand if this column is grouped
     */
    autoExpandGroup: z.boolean().optional(),

    /** The caption of the column. Dx defaults this to a capitalized dataField */
    caption: z.string().optional(),

    /**
     * Key determining how to render the cell
     * @default 'default'
     */
    cellRenderKey: z.string().default('default'),

    /**
     * How many columns the cell should span in edit mode
     * @default 6
     */
    colSpan: z
        .union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5), z.literal(6), z.literal('max')])
        .default(6),

    /** Id of the column */
    dataField: z.string(),

    /** The columns datatype. Dx attempt to infer if not set */
    dataType: z.enum(['string', 'number', 'date']).optional(),

    /** Generic editor options. Passed directly onto Dx's editor of choice */
    editorOptions: z.any().optional(),

    /** Editor type if a special one is needed */
    editorType: z.enum(['dxTextArea']).optional(),

    /** Format specifiers for numbers or dates */
    format: z.string().or(zFormatter).optional(),

    /** Index of the column in the edit form */
    formIndex: z.number().optional(),

    /** Index used for column ordering */
    gridIndex: z.number().optional(),

    /**
     * Index used for grouping rows with identical values in this column.
     *
     * For example used for grouping all activites from the same building
     */
    groupIndex: z.number().optional(),

    /** Hide caption while editing */
    hideCaption: z.boolean().default(false),

    /** Options to set if editor should use a lookup instead of the datatypes regular editor */
    lookup: zLookup.optional(),

    /** Max length for inputs. Not validated on initial data load */
    maxLength: z.number().optional(),

    /** Min width of the column. Numbers are interpreted as pixels */
    minWidth: z.number().or(z.string()).optional(),

    /** Rules for validating data */
    validationRules: zValidationRules.default(zValidationRules.parse({})),

    /** Wether the column should be pickable in the column chooser */
    showInColumnChooser: z.boolean().default(true),

    /** Index for sorting rows on this column. Lower indexes have priority */
    sortIndex: z.number().optional(),

    /** Sort ascending or descending */
    sortOrder: z.enum(['asc', 'desc']).optional(),

    /**
     * Whether column is visible in the grid
     *
     * @default true
     */
    visible: z.boolean().default(true),

    /** Whether column is visible in the edit form
     *
     * @default true
     */
    visibleInForm: z.boolean().default(true),

    /** Whether column is visible in the edit form if no data is present
     *
     * @default true
     */
    visibleIfNoData: z.boolean().default(true),

    /** Width of the column. Numbers are interpreted as pixels */
    width: z.number().or(z.string()).optional(),

    /** Used for layout, when two fields are paired */
    pairName: z.string().optional(),
});

export const zCommand = z.object({
    /** Name of the command */
    name: z.enum(['cancel', 'delete', 'edit', 'save', 'undelete']).or(z.nativeEnum(CommandRenderKeys)),

    /**
     * Whether the command shows up in the command column.
     * Some commands have effects outside button clicks, so may not have to be seen
     *
     * @default true
     */
    visible: z.boolean().default(true),

    /**
     * Disable the command
     *
     * @default false
     */
    disabled: z.boolean().default(false),

    /** Hint to display on hover */
    hint: z.string().optional(),

    /** Text for the "others"/"more" menu */
    text: z.string().optional(),

    /** Move button to "others" context menu (3 little dots) */
    asMenuItem: z.boolean().default(false),

    /** Overwrites the grid's base url for this command.  */
    urlOverride: z.string().optional(),

    /**
     * Props for a potential fileuploader. Only use if required by the chosen command.
     *
     * TODO: Alter command type so this only shows up when needed
     */
    uploadProps: z.custom<UploaderOptions>(() => true).optional(),
    /**
     * To hide button, but still have it clickable
     * Used in drift to have the skema button as a child of the grid instead of the tooltip in the more menu
     *
     * @default false
     */
    hidden: z.boolean().default(false),
});

export const zSpecialColumn = z.object({
    /** Name of the special column */
    name: z.enum(['command']),

    /** Place column before or after the main coloumns */
    position: z.enum(['before', 'after']).default('after'),

    /** Width in pixels */
    width: z.number().optional(),

    /**
     * Commands to show in the command column
     *
     * TODO: Alter type depending on name
     */
    items: z.array(zCommand),

    /** Caption of the column */
    caption: z.string().optional(),

    /** Wether the column should be pickable in the column chooser or keep its default behaviour */
    showInColumnChooser: z.boolean().optional(),
});

export const zExtras = z.object({
    /** @default true */
    allowEditing: z.boolean().default(true),

    /** @default false */
    allowAdding: z.boolean().default(false),

    /** @default false */
    allowDeleting: z.boolean().default(false),

    /** @default false */
    allowFiles: z.boolean().default(false),

    /** @default false */
    allowImages: z.boolean().default(false),

    /**
     * Sets the default behaviour for initially expanding groups
     *
     * Can be overriden by setting `autoExpandGroup` on a column
     *
     * @default true
     */
    autoExpandGroups: z.boolean().default(true),

    /** @default true */
    showColumnHeaders: z.boolean().default(true),

    /** @default false */
    showLookup: z.boolean().default(false),

    lookupUrl: z.string().optional(),

    /**
     * @default
     * true // on mobile
     * false // on desktop
     */
    showColumnLines: z.boolean().default(isMobile),

    /** @default false */
    egenskaber: z.boolean().default(false),

    /** @default false */
    showColumnChooser: z.boolean().default(false),

    /**@default false */
    showHeaderFilter: z.boolean().default(false),

    /** @default false */
    showFilterRow: z.boolean().default(false),

    context: z.string().optional(),

    /**
     * Changes default behaviour to opening skema instead of editing row
     *
     * NOTE: Only works with a visible, non menu item, Roll command
     *
     * @default false
     */
    schemaOnRowClick: z.boolean().default(false),

    /** @default false */
    export: z.boolean().default(false),

    /** @default "standard" */
    scrollingMode: z.enum(['standard', 'virtual']).default('standard'),
});

export const zLayout = z.object({
    columns: z.array(zColumn),
    specialColumns: z.array(zSpecialColumn).optional(),
    extras: zExtras.default(zExtras.parse({})),
});

export type ILayout = z.input<typeof zLayout>;
export type ZLayout = z.output<typeof zLayout>;
export type IColumn = z.input<typeof zColumn>;
export type ZColumn = z.output<typeof zColumn>;
export type ILookup = z.input<typeof zLookup>;
export type ZLookup = z.output<typeof zLookup>;
export type IExtras = z.input<typeof zExtras>;
export type ZExtras = z.output<typeof zExtras>;
export type ISpecialColumn = z.input<typeof zSpecialColumn>;
export type ZSpecialColumn = z.output<typeof zSpecialColumn>;
export type ICommand = z.input<typeof zCommand>;
export type ZCommand = z.output<typeof zCommand>;
