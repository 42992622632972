import CaretakerMap, { IMapLayer } from '../../components/map/caretaker-map';
import { getDragboxFeatures, shapeTypes } from '../../components/map/src/EditingControl';

import { Feature } from 'ol';
import { FeatureProperty } from '../../components/map/src/feature-utility';
import useCTIds from '../../shared/hooks/use-ct-ids';
import useStable from '../../shared/hooks/use-stable';
import { useEffect, useRef } from 'react';
import { FeatureStyleKey, urlLayerArray } from '../../components/map/src';
import { ISearchSource } from '../../components/map/src/react-controls/search-features/interfaces';
import useMountEffect from '../../shared/hooks/use-mount-effect';
import { ControlNames } from '../../components/map/interfaces';
import Geometry from 'ol/geom/Geometry';
import { ClickableFeature } from '../../components/map/src/ClickableFeatures';
import { useEnhedId } from '../../shared/hooks/use-enhed-id';
import { useCTEffect } from '../../shared/hooks/use-ct';
import { useSearchParams } from 'react-router-dom';
import { bbox } from 'ol/loadingstrategy';

const highlightLayers = (enhIds: string[]) => {
    const selectedFeatures: Feature<Geometry>[] = [];
    urlLayerArray.forEach((l) => {
        const features = l.getSource()?.getFeatures();
        if (!features) return;
        features.forEach((f) => {
            const data: ISearchSource = f.get(FeatureProperty.Search);
            const enhId = data?.parents?.enhId?.toString();

            if (enhId && enhIds.includes(enhId)) {
                f.set(FeatureProperty.StyleKeys, FeatureStyleKey.Selected);
                selectedFeatures.push(f);
            } else {
                f.set(FeatureProperty.StyleKeys, undefined);
            }
        });
    });
    return selectedFeatures;
};

const useHighlightedFeatures = () => {
    // const { enhIds } = useCTIds();
    const enhId = useEnhedId();
    const enhIds = [enhId.toString()];

    useMountEffect(() => {
        setTimeout(() => {
            const selectedFeatures = highlightLayers(enhIds);
            if (selectedFeatures.length > 0) ClickableFeature.ZoomFeatures(selectedFeatures, { maxZoom: 13 });
        }, 2000);
    });

    useCTEffect(() => {
        const selectedFeatures = highlightLayers(enhIds);
        if (selectedFeatures.length > 0) ClickableFeature.ZoomFeatures(selectedFeatures, { maxZoom: 13 });
    }, [enhId]);
};

export const getFeatureId =
    (idKey: string) =>
    (feature: Feature): string => {
        return feature.get(FeatureProperty.Search).parents[idKey].toString();
    };

const useOverviewLayers = () => {
    // useStable gives an always updated reference, needed for the event handler
    // Without it, the event handler would always have the initial value of idList
    const idList = useStable(useCTIds());
    const [, setParams] = useSearchParams();

    const layers: IMapLayer[] = [
        {
            type: 'POLY',
            displayName: 'Ejendomme',
            url: '/map/layer/-1?mapid=12',
            minZoom: 0,
            maxZoom: 9999,
            isCluster: false,
            clusterOptions: {
                distance: 0,
            },
            readOnly: 0,
            style: 'auto',
            shapeType: shapeTypes.Point,
            color: '#CC3333',
            declutter: true,
            svgString: 'Adr',
            events: {
                interactions: {
                    select: {
                        select: (e) => {
                            const getId = getFeatureId('enhId');
                            const selected = e.selected.map(getId);
                            // idList.current.updateEnhIds((ids) =>
                            //     selected.concat(e.mapBrowserEvent.originalEvent.shiftKey ? ids : [])
                            // );
                            setParams({ ids: 'enh-' + selected[0] });
                        },
                    },
                    // dragBox: {
                    //     boxend: (e, { layer }) => {
                    //         const getId = getFeatureId('enhId');
                    //         const features = getDragboxFeatures(e.target, layer);
                    //         idList.current.updateEnhIds((ids) => ids.concat(features.map(getId)));
                    //     },
                    // },
                },
            },
        },
        {
            type: 'POLY',
            displayName: 'Test',
            loadingStrategy: bbox,
            maxResForBboxFetch: 0.41961669921875,
            url: '/map/layer/-2?mapid=12',
            minZoom: 13,
            style: 'auto',
            shapeType: shapeTypes.Polygon,
        },
        // {
        //     type: 'POLY',
        //     displayName: 'Test2',
        //     url: '/map/layer/-2?mapid=12',
        // },
    ];

    return { layers };
};

const OverviewMap = () => {
    useHighlightedFeatures();
    const { layers } = useOverviewLayers();

    return (
        <CaretakerMap
            id='Overview'
            controls={[{ name: ControlNames.Overview }, { name: 'lmenu', noGui: false }]}
            layers={layers}
        />
    );
};

export default OverviewMap;
