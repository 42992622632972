import { BarDatum, BarTooltipProps, ResponsiveBar } from '@nivo/bar';

import { BasicTooltip } from '@nivo/tooltip';
import LoadingScreen from '../../screens/loading';
import { useEnhedId } from '../../shared/hooks/use-enhed';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';

export const colors = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
    '#aec7e8',
    '#ffbb78',
    '#98df8a',
    '#ff9896',
    '#c5b0d5',
    '#c49c94',
    '#f7b6d2',
    '#c7c7c7',
    '#dbdb8d',
    '#9edae5',
];

interface InputDataItem {
    year: number;
    dkk: number;
    stack: string;
}

interface TransformedDataItem {
    year: number;
    [key: string]: number | string;
}

const StackedBarGraph = ({ stack, bygning }: { stack?: string; bygning?: number }) => {
    const enhedsys = useEnhedId();
    const [data, isLoading] = useFetch<InputDataItem[]>(
        `/Plan/Vhbehov?enhedsys=${enhedsys}${(stack?.length ?? 0) > 0 ? `&stack=${stack}` : ''}${
            bygning && bygning !== 0 ? `&bygtersys=${bygning}` : ''
        }`
    );

    const transformData = (inputData: InputDataItem[]): TransformedDataItem[] => {
        const transformedData: TransformedDataItem[] = [];

        inputData.forEach((item) => {
            const existingItem = transformedData.find((data) => data.year === item.year);
            if (existingItem) {
                existingItem[item.stack.trim() !== '' ? item.stack.trim() : 'value'] = item.dkk;
                // existingItem[(item.stack.trim() !== '' ? item.stack.trim() : 'value') + 'Color'] = colors[i];
            } else {
                const newItem: TransformedDataItem = { year: item.year };
                newItem[item.stack.trim()] = item.dkk;
                // newItem[item.stack.trim() + 'Color'] = colors[++i];
                transformedData.push(newItem);
            }
        });

        return transformedData;
    };

    const CustomTooltip = (e: BarTooltipProps<BarDatum>) => {
        return (
            <BasicTooltip
                id={`${e.id ? `${String(e.id).trim()} - ` : ''}${e.indexValue}`}
                value={`${e.value.toLocaleString()} kr.`}
            />
        );
    };

    if (isLoading || !data) {
        return <LoadingScreen />;
    }
    if (data.length < 1) {
        return <div>Ingen data</div>;
    }
    return (
        <div style={{ width: '100%', height: '300px' }}>
            <ResponsiveBar
                data={transformData(data)}
                keys={Object.keys(transformData(data)?.[0])
                    .filter((key) => key !== 'year' && !key.includes('Color'))
                    .sort()}
                indexBy={'year'}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                colors={colors}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    format: (e) => `${e.toLocaleString()}`,
                }}
                margin={{ top: 20, right: 200, bottom: 50, left: 80 }}
                labelSkipHeight={8}
                labelSkipWidth={50}
                tooltip={(e) => CustomTooltip(e)}
                label={(e) => `${e.value?.toLocaleString()}`}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                motionConfig={'wobbly'}
                labelFormat={'.0s'}
            />
        </div>
    );
};
export default StackedBarGraph;
