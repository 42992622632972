//#region Imports

import 'ol/ol.css';
import 'ol/events/condition';

import { Circle, Point } from 'ol/geom';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from 'ol/style';
import { Control, defaults as defaultControls } from 'ol/control';
import { EditingControl, shapeTypes } from './EditingControl';
import Feature, { FeatureLike } from 'ol/Feature';
import { ILayer, LayerMenuControl, Layers } from './LayerMenuControl';
import { IMapControl, IMapLayer } from '../caretaker-map';
import { SearchDKControl, searchAddress } from './deprecated/SearchDKControl';
import { get as getProjection, transform } from 'ol/proj';
import { importWktFromUrl, wktFormat } from './LayerImportExport';

import { BygLayer } from './deprecated/BygLayer';
import CTLayer, { getThemeOrDefault } from './CTLayer';
import { ClickableFeature } from './ClickableFeatures';
import { Colors } from './Colors';
import { ControlHandler } from './ControlHandler';
import { ControlNames } from '../interfaces';
import { EnhLayer } from './deprecated/EnhLayer';
import { FeatureProperty } from './feature-utility';
import { GPSControl } from './GPSControl';
import { IDictionary } from '../../../shared/utils/types';
import { LokLayer } from './deprecated/LokLayer';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { Notifyer } from './Notifyer';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import { OverviewControl } from './react-controls/overview-screen-control/overview-control';
import PinLayer from './PinLayer';
import { PlaygroundControl } from './react-controls/playground/control';
import PluggableMap from 'ol/PluggableMap';
import Projection from 'ol/proj/Projection';
import ReportDriftErrorControl from './react-controls/report-drift-error/control';
import { SQLControl } from './deprecated/SQLControl';
import { SearchFeaturesControl } from './react-controls/search-features/control';
import { SqlLayer } from './deprecated/SqlLayer';
import { StaticCookieHandler } from './CookieHandler';
import Text from 'ol/style/Text';
import Units from 'ol/proj/Units';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import View from 'ol/View';
import { WktIEControl } from './WktIEControl';
import { addEquivalentProjections } from 'ol/proj';
import { cmSettings } from '../src/CaretakerMapSettings';
import { decodeJsonResponse } from './deprecated/DataIO';
import { defaults as defaultInteractions } from 'ol/interaction';
import { getIconSrc } from './react-controls/create-gui/create-layer-icon';
import { getUser } from '../../../shared/hooks/redux-use-user';
import { isArray } from 'lodash';
import { isMobile } from 'react-device-detect';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { Themes } from './react-controls/create-gui/create-layer-gui';
import { run } from '../../../shared/utils/utils';
import { AddAddressControl } from './react-controls/add-address-control';
import { bbox } from 'ol/loadingstrategy';

//#endregion Imports

//#region Map Init Event

export const mapEventTarget = new EventTarget();
export let map: PluggableMap | null = null;

//#endregion Map Init Event

export const setCenter = (onUser: boolean, enhId?: number | string) => {
    GPSControl.centerOnUser = onUser;
    StaticCookieHandler.centerEnhed = enhId;
};

//#region Projection Configuration

proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

// EPSG Projections allows for distances to be meassured in meters.
proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

register(proj4);

const proj4326 = getProjection('EPSG:4326');
proj4326?.setExtent([-18e6, -9e6, 18e6, 9e6]);

const proj25832 = getProjection('EPSG:25832');
proj25832?.setExtent([120000, 5900000, 1000000, 6500000]);

// override the axis orientation for WMS GetFeatureInfo
const proj = new Projection({
    code: 'EPSG:25832',
    units: Units.METERS,
    extent: [120000, 5900000, 1000000, 6500000],
    axisOrientation: 'enu',
    global: false,
    metersPerUnit: 1,
    worldExtent: [-1877994.66, 3932281.56, 836715.13, 9440581.95],
});

const temp_proj = getProjection('EPSG:25832');
if (temp_proj != null) addEquivalentProjections([temp_proj, proj]);

//#endregion Projection Configuration

// This is exported as most codew was structured where this functionally didn't change anything anyway. The same Layers object is used everywhere
export let layers = new Layers();

// Nedenstående er flyttet til setLayers, hvor ovenstående linje også sker igen. Det stopper lagende fra at blive flere og flere hver gang koden her kører

//#region Layer Drawing

const drawingLayer = new VectorLayer({
    source: new VectorSource({
        features: [],
    }),
    style: new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.4)',
        }),
        stroke: new Stroke({
            color: '#ff4444',
            width: 2,
        }),
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: '#ff4444',
            }),
        }),
    }),
    zIndex: 1000,
});

//#endregion Layer Drawing

//#region Ipad Help Link

const ipadHelpControl = new Control({
    element: ((_) => {
        const div = document.createElement('div');
        div.className = 'ol-ipadHelpControl';
        const a = document.createElement('a');
        a.innerHTML = 'Tryk her hvis touch funktionerne ikke virker på din ipad.';
        a.href = 'ipadHelp.html';
        div.appendChild(a);
        return div;
    })(),
});

//#endregion Ipad Help Link

//#region Test Buttons Control

const testButtonControl = new Control({
    element: ((_) => {
        return document.getElementById('testButtonControl') ?? undefined;
    })(),
});

//#endregion Test Buttons Control

//#region Marker

let markerOverlay: Overlay | null = null;
if (cmSettings.showMarker) {
    const touchIsEnabled = navigator.userAgent.match(
        /(iPhone|iPod|blackberry|android 0.5|htc|lg|midp|mmp|mobile|nokia|opera mini|palm|pocket|psp|sgh|smartphone|symbian|treo mini|Playstation Portable|SonyEricsson|Samsung|MobileExplorer|PalmSource|Benq|Windows Phone|Windows Mobile|IEMobile|Windows CE|Nintendo Wii)/i
    );
    const saveCoords = cmSettings.saveCoords;

    const img = document.createElement('img');
    img.src = cmSettings.markerImgPath;

    if (touchIsEnabled && cmSettings.markerScaleOnMobile != null) {
        img.width = Math.round(23 * cmSettings.markerScaleOnMobile);
        img.height = Math.round(32 * cmSettings.markerScaleOnMobile);
    } else {
        img.width = 23;
        img.height = 32;
    }

    markerOverlay = new Overlay({
        element: img,
        positioning: OverlayPositioning.BOTTOM_CENTER,
        stopEvent: false,
        position: cmSettings.markerCoords,
        offset: [0, 0],
    });

    //#region Events

    // Blå prik når mus er valgt følger marker? Tror jeg
    mapEventTarget.addEventListener('init', () => {
        let markerMove = false;
        const handleStart = (e: HTMLElementEventMap[keyof HTMLElementEventMap]) => {
            markerMove = true;
            e.preventDefault();
        };

        const handleEnd = (_: unknown) => {
            if (markerMove) {
                if (saveCoords.func != null) {
                    const coords = transform(markerOverlay!.getPosition()!, 'EPSG:25832', saveCoords.proj);
                    saveCoords.func(coords[0], coords[1]);
                } else {
                    // console.log(
                    //     'The marker have been moved, but no eventhandler was found.\n' +
                    //     'To make an eventhandler, define an object like the following:\n' +
                    //     'window[\'cm\'].saveCoords = {\n' +
                    //     '  proj: \'EPSG:25832\', //EPSG:4326\n' +
                    //     '  //This function will be executed, when the marker gets moved.\n' +
                    //     '  //The input parameters lat, lon will have the same projection as specified by \'proj\' \n' +
                    //     '  func: function(lat, lon){\n' +
                    //     '    console.log(lat);\n' +
                    //     '    console.log(lon);\n' +
                    //     '  }\n' +
                    //     '}'
                    // );
                }
            }
            markerMove = false;
        };

        markerOverlay?.getElement()!.addEventListener('touchstart', handleStart, false);
        markerOverlay?.getElement()!.addEventListener('mousedown', handleStart);

        let pointerCoords = cmSettings.markerCoords;
        //markerOverlay.getMap().on('pointermove', e => {
        map!.on('pointermove', (e: MapBrowserEvent<UIEvent>) => {
            pointerCoords = e.coordinate as [number, number];
            if (markerMove) {
                e.stopPropagation();
                markerOverlay?.setPosition(pointerCoords);
            }
        });

        //var mapGuiElement: HTMLElement = markerOverlay.getMap().getTargetElement();
        const mapGuiElement: HTMLElement = map!.getTargetElement();
        mapGuiElement.addEventListener('touchend', handleEnd, false);
        mapGuiElement.addEventListener('mouseup', handleEnd);
    });

    //#endregion Events
}

//#endregion Marker

//#region Import WKT From String

function importWktFromString(wktStr: string, proj = 'EPSG:25832') {
    drawingLayer.getSource()?.addFeatures(
        wktFormat.readFeatures(wktStr, {
            dataProjection: 'EPSG:25832',
            featureProjection: proj,
        })
    );
}

//#endregion Import WKT From String

export function readableColor(inputColor: string): string {
    const colorValue = parseInt(inputColor.replace('#', ''), 16);
    if (colorValue > 0xffffff / 2) {
        return '#000';
    } else {
        return '#FFF';
    }
}

export enum FeatureStyleKey {
    Default,
    Boundary,
    Hidden,
    Selected,
}

const selectedStyleFill = new Fill({
    color: 'rgba(255,255,255,0.4)',
});
const selectedStyleStroke = new Stroke({
    color: '#3399CC',
    width: 1,
});
export const selectedStyle = new Style({
    fill: selectedStyleFill,
    stroke: selectedStyleStroke,
    image: new CircleStyle({
        fill: selectedStyleFill,
        stroke: selectedStyleStroke,
        radius: 5,
    }),
});

// Style for layers that is exported, to be used after changing style while editing
// Function is messy as the conditions for features' styles have historically been different, and no one system was developed.
//! If possible, FeatureStyleKeys should be used from now on
/**
 *
 * @returns array of styles for the possibility to have an image and a geometry together
 */
export const polyLayerStyle = (
    feature: FeatureLike,
    resolution: number,
    polyLayer: CTLayer,
    floor: string,
    theme: Themes
): Style[] => {
    const featureData = feature.get(FeatureProperty.Data);
    const fFloor = featureData?.floor;
    const featureColor = getThemeOrDefault({ feature, theme, layer: polyLayer });

    const styles = [];

    let styleKeys: FeatureStyleKey[] = feature.get(FeatureProperty.StyleKeys) ?? [FeatureStyleKey.Default];
    !isArray(styleKeys) && (styleKeys = [styleKeys]);

    if (styleKeys.includes(FeatureStyleKey.Hidden)) return [new Style({})];

    if (styleKeys.every((k) => k === FeatureStyleKey.Default)) {
        // Check correct floor of feature
        if (floor === '' ? fFloor != null : floor === 'A' ? fFloor == null : fFloor !== floor) {
            return [new Style({}), new Style({})];
        }

        if (polyLayer.options.shapeType === shapeTypes.Polygon) {
            return [
                new Style({
                    fill: new Fill({
                        color: featureColor,
                    }),
                    stroke: new Stroke({
                        color: 'white',
                    }),
                    text: new Text({
                        font: '12px "Segoe UI", Verdana, Tahoma, sans-serif',
                        fill: new Fill({
                            color: readableColor(
                                featureColor
                            ),
                        }),
                        text: resolution < 2 ? featureData?.label ?? '' : '',
                    }),
                }),
            ];
        }
    }

    // Gets a textSVG, when the prop is set
    const svgString = featureData?.svg ?? polyLayer.options.svgString;
    if (polyLayer.options.svgString != null) {

        const scale = run(() => {
            const initialScale = run(() => {

                if (resolution > 1) return 0.4;
                if (resolution > 0.1) return 0.7;
                if (resolution > 0.05) return 0.9;
                return 1.2;
            })
            return initialScale;
        })

        const icon = new Icon({
            opacity: 1,
            src: getIconSrc(svgString, featureColor),
            scale,
        });

        styles.push(
            new Style({
                image: resolution < 0.6 ? icon : undefined,
                text: new Text({
                    font: '13px "Segoe UI", Verdana, Tahoma, sans-serif',
                    fill: new Fill({ color: '#FFF' }),
                    stroke: new Stroke({
                        color: '#000',
                        width: 2,
                    }),
                    text: resolution < 2 ? featureData?.label ?? '' : '',
                    textBaseline: 'bottom',
                    offsetY: -3,
                }),
            })
        );
    }

    styleKeys.forEach((k) => {
        switch (k) {
            case FeatureStyleKey.Boundary:
                styles.push(
                    new Style({
                        stroke: new Stroke({
                            color: 'black',
                            width: 2,
                        }),
                        text: feature.get('boundaryText'),
                    })
                );
                break;
            case FeatureStyleKey.Default:
            default:
                styles.push(
                    new Style({
                        fill: new Fill({
                            color: featureColor,
                            // color: Colors.colorFromOpacity(i % Colors.staticRGBColors.length, 0.2)
                        }),
                        stroke: new Stroke({
                            color: featureColor,
                            // color: Colors.staticRGBColors[i % Colors.staticRGBColors.length],
                            width: 4,
                        }),
                        text: new Text({
                            font: '13px "Segoe UI", Verdana, Tahoma, sans-serif',
                            fill: new Fill({ color: '#FFF' }),
                            stroke: new Stroke({
                                color: '#000',
                                width: 2,
                            }),
                            text: resolution < 2 ? featureData?.label ?? '' : '',
                            textBaseline: 'bottom',
                            offsetY: -3,
                        }),
                        geometry: new Circle(
                            (feature as Feature<Point>).getGeometry()?.getCoordinates() ?? [],
                            (15 * resolution) / 10
                        ),
                    })
                );
                break;
            // Default select style from https://openlayers.org/en/latest/apidoc/module-ol_style_Style-Style.html
            case FeatureStyleKey.Selected:
                styles.push(selectedStyle);
                break;
        }
    });

    return styles;
};

const asyncNotifyer: Notifyer = new Notifyer();

/** Array of all current url layers */
export let urlLayerArray = Object.values(layers.getUrlLayers()).map((iLayer) => iLayer.layer);

export const setLayers = (customLayers: IMapLayer[], baseLayers?: { name: string }[]) => {
    layers = new Layers();
    layers.setupDefaultLayers(cmSettings.auth, undefined, baseLayers);

    for (let i = 0; i < customLayers.length; i++) {
        const l = customLayers[i];
        const style: Style = new Style({
            fill: new Fill({
                color: Colors.colorFromOpacity(i % Colors.staticRGBColors.length, 0.2),
            }),
            stroke: new Stroke({
                color: Colors.staticRGBColors[i % Colors.staticRGBColors.length],
                width: 4,
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: Colors.colorFromOpacity(i % Colors.staticRGBColors.length, 1),
                }),
            }),
        });
        let args: string = '';
        switch (l.type) {
            case 'ENH':
                const enhLayerSetting = l;
                const enhLayer = new EnhLayer(enhLayerSetting, asyncNotifyer);
                enhLayer.color = '#56aaff';
                layers.addUrlLayer(`ENH_${enhLayerSetting.type}`, { displayName: 'Enheder', layer: enhLayer });
                if (enhLayerSetting.enhId != null) {
                    args += `&enhSys=${enhLayerSetting.enhId}`;
                }
                enhLayer.loadEnhDots(
                    `${cmSettings.endpointGetFeatures}?view=${enhLayerSetting.view}${args}`,
                    enhLayerSetting.geometryColumn!,
                    enhLayerSetting?.status
                );
                //enhLayer.setStyle(style);
                break;
            case 'BYG':
                const bygLayerSetting = l;
                const bygLayer = new BygLayer(bygLayerSetting);
                bygLayer.color = Colors.staticHexColors[i];
                layers.addUrlLayer(`BYG_${l.type}`, { displayName: 'Bygninger', layer: bygLayer });
                if (bygLayerSetting.enhId != null) {
                    args += `&enhSys=${bygLayerSetting.enhId}`;
                }
                if (bygLayerSetting.bygId != null) {
                    args += `&bygSys=${bygLayerSetting.bygId}`;
                }
                bygLayer.loadBygPolygons(`${cmSettings.endpointGetFeatures}?view=${l.view}${args}`, l.geometryColumn!);
                break;
            case 'LOK':
                const lokLayerSetting = l;
                const lokLayer = new LokLayer(lokLayerSetting);
                lokLayer.color = Colors.staticHexColors[i];
                layers.addUrlLayer(`Lok_${l.type}`, { displayName: 'Lokaler', layer: lokLayer });
                if (lokLayerSetting.enhId != null) {
                    args += `&enhSys=${lokLayerSetting.enhId}`;
                }
                if (lokLayerSetting.bygId != null) {
                    args += `&bygSys=${lokLayerSetting.bygId}`;
                }
                if (lokLayerSetting.bygId != null) {
                    args += `&lokSys=${lokLayerSetting.bygId}`;
                }
                lokLayer.loadLokPolygons(`${cmSettings.endpointGetFeatures}?view=${l.view}${args}`, l.geometryColumn!);
                break;
            case 'DEL':
                const sqlLayerSetting = l;
                const sqlLayer = new SqlLayer(sqlLayerSetting);
                sqlLayer.color = Colors.staticHexColors[i];
                layers.addUrlLayer(`DEL_${sqlLayerSetting.view}`, {
                    displayName: sqlLayerSetting.displayName,
                    layer: sqlLayer,
                });
                if (sqlLayerSetting.enhId != null) {
                    args += `&enhSys=${sqlLayerSetting.enhId}`;
                }
                if (sqlLayerSetting.bygId != null) {
                    args += `&bygSys=${sqlLayerSetting.bygId}`;
                }
                sqlLayer.setLoader(
                    `${cmSettings.endpointGetFeatures}?view=${sqlLayerSetting.view}${args}`,
                    sqlLayerSetting.geometryColumn!
                );
                sqlLayer.loader();
                sqlLayer.setStyle(style);
                break;
            case 'PIN':
                const pinLayer = new PinLayer(l);
                pinLayer.color = '#f57842';
                layers.addUrlLayer(`PIN_${l.displayName}`, { displayName: l.displayName, layer: pinLayer });
                // pinLayer.reloadPins();
                break;
            case 'POLY':
                const polyLayer = new CTLayer(l);
                polyLayer.color = polyLayer.options.color ?? '#f57842';
                // polyLayer.setStyle((feature, resolution) => polyLayerStyle(feature, resolution, polyLayer, 'S'));
                polyLayer.setStyle((feature, resolution) =>
                    polyLayerStyle(feature, resolution, polyLayer, '', Themes.theme0)
                );
                layers.addUrlLayer(`POLY_${l.displayName}`, { displayName: l.displayName, layer: polyLayer });
                break;
        }
    }
    urlLayerArray = Object.values(layers.getUrlLayers()).map((iLayer) => iLayer.layer);
};

export const initMap = () => {
    map?.dispose();
    map = new Map({
        target: `map-root`,
        layers: layers.getLayersRaw().extend([drawingLayer]), //, new GeolocationLayer()]),
        interactions: defaultInteractions({ pinchRotate: false, altShiftDragRotate: false }),
        // overlays: [markerOverlay],
        view: new View({
            projection: 'EPSG:25832',
            center: cmSettings.markerCoords,
            zoom: cmSettings.startZoomLevel,
        }),
        controls: defaultControls(), //.extend([new LayerMenuControl(layers), new DrawControl(drawingLayer, { iconsFolder: cmSettings.iconFolderPath }), new WktIEControl(drawingLayer)/*, new BuildingControl(buildingLayers)*/, new SearchDKControl({ iconsFolder: cmSettings.iconFolderPath }), ipadHelpControl, testButtonControl])
    });

    const event = new Event('init');
    mapEventTarget.dispatchEvent(event);

    return map;
};

//#region Resize

// Make sure map always resizes

let observer: ResizeObserver | undefined;
mapEventTarget.addEventListener('init', () => {
    // Size is assumed to be constant on mobile to avoid the map resizing whenever the keyboard is opened
    // Changing between portrait and landscape mode is covered in a caretaker-map.tsx useLayoutEffect
    if (isMobile) return;

    // Stop observing previous maps
    observer?.disconnect();

    // Observe current map
    const root = document.getElementById('map-root');
    observer = new ResizeObserver(() => map!.updateSize());
    console.assert(observer != null, 'No #map-root DOM element was found for the resizeObserver');
    observer.observe(root!);
});

//#endregion Resize

mapEventTarget.addEventListener('init', () => {
    setTimeout(() => map!.updateSize(), 200);
});

let controls: IMapControl[];

export const setControls = (ctrls: IMapControl[]) => {
    controls = ctrls;
};

mapEventTarget.addEventListener('init', () => {
    //let controls = MapData.controls;

    const m = map as Map;

    // Typecast is a lie but editing can only activate by switching layer anyway. Default is here for backwards compatibility
    const controlHandler = new ControlHandler({
        displayName: 'Default Drawing Layer',
        layer: drawingLayer,
    } as ILayer<CTLayer>);

    controls.forEach((ctrl) => {
        switch (ctrl.name) {
            case 'lmenu':
                m.addControl(
                    new EditingControl(
                        controlHandler,
                        SQLControl.RetrieveSqlILayers(layers).map((iLayer) => iLayer.layer)
                    )
                );
                m.addControl(new LayerMenuControl(controlHandler, layers));
                break;
            case 'wkt':
                m.addControl(new WktIEControl(controlHandler));
                break;
            case 'search':
                m.addControl(new SearchDKControl(controlHandler, { iconsFolder: cmSettings.iconFolderPath }));
                break;
            case 'dev':
                m.addControl(testButtonControl);
                break;
            case 'ipad':
                m.addControl(ipadHelpControl);
                break;
            case 'gps':
                m.addControl(new GPSControl(controlHandler, new VectorLayer({ map: m })));
                break;
            case 'report-error':
                m.addControl(
                    new ReportDriftErrorControl(controlHandler, ReportDriftErrorControl.retrieveILayer(layers))
                );
                break;
            case 'search-features':
                m.addControl(new SearchFeaturesControl(controlHandler, layers.getUrlLayers(), ctrl));
                break;
            case ControlNames.AddAddress:
                m.addControl(new AddAddressControl(controlHandler, urlLayerArray));
                break;
            case ControlNames.Overview:
                m.addControl(new OverviewControl(controlHandler, urlLayerArray));
        }
    });

    if (getUser().role === 'admin') m.addControl(new PlaygroundControl(controlHandler));

    StaticCookieHandler.init(controlHandler, m, asyncNotifyer, layers);

    // Cursor
    m.on('pointermove', (e) => {
        // Ændre cursor på hover ved select og delete
        let features = 0;
        map!.forEachFeatureAtPixel(
            e.pixel,
            () => {
                features++;
            },
            {
                layerFilter: (layer) => {
                    try {
                        return (layer as SqlLayer).options.displayName === controlHandler.activeILayer.displayName;
                    } catch (e) {
                        return false;
                    }
                },
            }
        );
        if (features > 0) document.body.style.cursor = 'pointer';
        else document.body.style.cursor = 'default';
    });
});

mapEventTarget.addEventListener('init', () => {
    ClickableFeature.addPopupEventListener(map!);
});

//#region Unknown logging

// mapEventTarget.addEventListener('init', (me: any) => {
//     me['map'].on('click', (e: any) => {
//         (layers.getOverlays()['byg'].layer.getSource() as VectorSource<any>)?.getFeaturesAtCoordinate(e.coordinate).forEach(f => {
//             console.log('Bygning');
//             console.log(f);
//             let xmlData = f.getProperties().xmlData;
//             console.log('XMLData:');
//             console.log(xmlData);
//             console.log('BBRUUID: ' + xmlData.getElementsByTagName('gdk60:BBRUUID')[0].innerHTML);
//         });
//         (layers.getOverlays()['sfe'].layer.getSource() as VectorSource<any>)?.getFeaturesAtCoordinate(e.coordinate).forEach(f => {
//             console.log('SamletFastEjendom');
//             console.log(f);
//             let xmlData = f.getProperties().xmlData;
//             console.log('XMLData:');
//             console.log(xmlData);
//             console.log('BFEnummer: ' + xmlData.getElementsByTagName('mat:BFEnummer')[0].innerHTML);
//         });
//     });
// });

//#endregion Unknown logging

mapEventTarget.addEventListener('init', () => {
    map!.on('moveend', () => {
        const zoomDiv = document.getElementById('zoomNiveau');
        zoomDiv != null && (zoomDiv.innerHTML = map!.getView().getZoom()?.toFixed(2).toString() ?? '');

        // If using the bbox strategy, fetch data every time the map moves
        Object.keys(layers.urlLayers).forEach((l) => {
            const shouldUpdate = layers.urlLayers[l].layer.options.bboxUpdateOnViewChange;
            if (shouldUpdate && layers.urlLayers[l].layer.options.loadingStrategy === bbox) {
                const extend = map!.getView().calculateExtent();
                const resolution = map!.getView().getResolution();
                (layers.urlLayers[l].layer as unknown as {values_: {source: {loader_: (extend: number[], res?: number)=> {}} }}).values_.source.loader_(extend, resolution);
            }
        });
    });
});

mapEventTarget.addEventListener('init', () => {
    map!.on('propertychange', (e) => {
        switch (e.key) {
            case 'view':
                map!.getView().setCenter(cmSettings.markerCoords);
                map!.getView().setZoom(cmSettings.startZoomLevel);
                break;
        }
    });
});

mapEventTarget.addEventListener('init', () => {
    map!.on('propertychange', (e) => {
        switch (e.key) {
            case 'view':
                map!.getView().setCenter(cmSettings.markerCoords);
                map!.getView().setZoom(cmSettings.startZoomLevel);
                break;
        }
    });
});

//mapEventTarget.addEventListener('init', me => buildingLayers.addEventListeners(me['map']));

function loadEnhRooms(dataEndpointUri: string, id: string, floorName: string, ifcClass: string, wkt: string) {
    // eslint-disable-next-line no-restricted-syntax
    fetch(dataEndpointUri)
        .then((r) => r.json())
        .then((json) => decodeJsonResponse(json))
        // Ligner gammelt kode der ikke er i brug fra Anders første omgang med kortet?
        .then(
            (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                json //@ts-ignore
            ) => layers['LOK'].readFeaturesFromDataTable(json, { storey: floorName, category: ifcClass, wkt: wkt }, id)
        );
}

//okfremdrift.csv

// window['ok'] = [];
// const dataEndpointUri = 'http://localhost/data/okfremdrift.csv';
// const statusColumnName = 'BesvarelsesProcent';
// const geometryColumnName = 'coords';
// fetch(dataEndpointUri).then(r => r.text())
//   .then(csv => CSVReader.read(csv))
//   .then(json => {

//     if(statusColumnName != null/* && statusColumnName != ''*/)
//       enhLayer.setStatusColumnName(statusColumnName);

//     let features = [];
//     json.map(o => {
//       let feature = null;

//       searchAddressByString(o.ADRESSE + ', ' + o.POSTNR).then(addr => {
//         console.log('addr: ', addr);

//         let coords = transform([addr[0].data.x, addr[0].data.y], 'EPSG:4326', 'EPSG:25832');
//         o.coords = coords;
//         o.geometry = toStringXY(coords);

//         window['ok'].push(o);
//         feature = ( o[geometryColumnName] == '' || o[geometryColumnName] === undefined ) ? null : new ClickableFeature(new Point(o[geometryColumnName]));
//         if (feature != null){
//           feature.set(FeatureProperty.Data, o);
//           (<ClickableFeature>feature).dataColumns = ["Anlæg","ADRESSE","POSTNR","Sidste retdato","BesvarelsesProcent"];
//           //(<ClickableFeature>feature).dataColumns = ['ENHEDSYS', 'ENHKODE', 'NAVN'];
//           //(<ClickableFeature>feature).dataColumns = ['EnhedSYS', 'Navn', 'Gade', 'AdrHusNr', 'Postnr', 'PostBy'];
//         }
//         else
//           console.log('o: ', o);
//         features.push(feature);
//       });
//     })

//     enhLayer.addPointsFromWKTPoints(features);

//   });

const cm = {
    initMap: initMap,
    loadEnhRooms: loadEnhRooms,
    // 'loadEnhDots': layers['ENH'].loadEnhDots,
    mapEventTarget: mapEventTarget,
    //geolocation = gPSDot,
    //transform: transform,
    importWktFromUrl: importWktFromUrl,
    importWktFromString: importWktFromString,
    // 'addEnhBygPoints': layers['ENH'].addPoints,
    // 'clearEnhBygPoints': layers['ENH'].clearPoints,
    searchAddress: searchAddress,
    // 'loadBygPolygons' : layers['BYG'].loadBygPolygons
    //  'readFeaturesFromDataTable': buildingLayers.readFeaturesFromDataTable,
};

(window as unknown as IDictionary<unknown>)['cm'] = cm;

if (cmSettings.initMap) {
    initMap();
}

//module.exports = cm;
