import { TimelineOptions } from 'vis-timeline';
import Api from '../../../../shared/networking/api';
import { InputStrategy } from '../../../auto-grid/popup-form';
import { colors } from '../../stacked-bar-graph';

const switchDotAndComma = (value: string | number) => {
    return parseFloat(value.toString().replaceAll('.', '').replaceAll(',', '.'));
};

export const mængdePrisInput: InputStrategy = async (e, id, data, changes, formRef) => {
    const localData = data;
    changes[id] = e.value ?? e.event?.currentTarget?.value;
    if (String(id).endsWith('maengde')) {
        const priceResponse = await Api.get<string>(
            `/Byg/Aktivitet/Lookup/Enhedspris?priskatalog=${localData.priskatalog}&mgd=${e.component._parsedValue}`
        );
        if (switchDotAndComma(priceResponse.data) > 0 || localData.enhedspris > 0) {
            const price = Math.max(switchDotAndComma(priceResponse.data), switchDotAndComma(localData.enhedspris));
            localData.enhedspris = price;
            formRef.current?.instance.updateData('nutidspris', Math.round(e.event.currentTarget.value * price));
            changes['nutidspris'] = Math.round(e.event.currentTarget.value * price);
            formRef.current?.instance.updateData('enhedspris', price);
            changes['enhedspris'] = price;
        }
    } else if (String(id).endsWith('enhedspris')) {
        if ((localData.maengde ?? 0) === 0) return;
        formRef.current?.instance.updateData(
            'nutidspris',
            Math.round(localData.maengde * switchDotAndComma(e.event?.currentTarget?.value))
        );
        changes['nutidspris'] = Math.round(localData.maengde * switchDotAndComma(e.event.currentTarget.value));
    } else return;
};

export const planTimelineOptions: TimelineOptions = {
    start: new Date(),
    end: new Date(1000 * 60 * 60 * 24 + new Date().valueOf()),
    width: '100%',
    // stack: false,
    margin: { item: 10 },
    zoomMin: 1000 * 60 * 60 * 24 * 90, // 90 days
    groupHeightMode: 'fixed',
    align: 'left',
};

export const createTimelineItemsGroupsAndOptions = (data: PlanData[], stack: string) => {
    const typedStack = stack as keyof typeof stackMap;
    if (stackMap[typedStack] === undefined) {
        return { items: [], groups: [], options: planTimelineOptions };
    }
    const stackForData = stackMap[typedStack] as keyof PlanData;
    const stackForContent = stackContent[stackForData] as keyof PlanData;
    const groups = data.reduce((acc, item) => {
        if (!acc.find((i) => i.id === (item?.[stackForData] ?? '-'))) {
            const content = item?.[stackForContent] ?? '-';
            const id = item?.[stackForData] ?? '-';
            acc.push({ id: id, content: content, count: 0, curr: 0 });
        }
        return acc;
    }, [] as { id: number | string; content: string | number; count: number; curr: number }[]);
    groups.sort((a, b) => {
        if (a.content > b.content) return 1;
        if (a.content < b.content) return -1;
        return 0;
    });
    if (groups[0].id === '-') {
        groups.push(groups.shift()!);
    }
    console.log(groups);
    // Create record to group items by group and start time
    const groupedByStartTime: Record<string, TransformedData[]> = data.reduce((result, item) => {
        const date = new Date(new Date(item.bedstetid).setHours(0, 0, 0, 0));
        const key = `${item?.[stackForData]}-${date}`;
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push({
            id: item.id,
            group: item?.[stackForData] ?? '-',
            content: item.beskrivelse,
            title: item.beskrivelse,
            start: date,
            end: date,
        });
        return result;
    }, {} as Record<string, TransformedData[]>);

    const items: TransformedData[] = [];
    // Spread item times evenly over the day for items that share the same day
    Object.values(groupedByStartTime).forEach((group) => {
        group.forEach((item) => {
            item.start = new Date(item.start.valueOf()); // + (i * 1000 * 60 * 60 * 24) / group.length);
            // item.end = new Date(item.start.valueOf() + (1000 * 60 * 60 * 24) / group.length);
            item.type = 'box';
            items.push(item);
        });
    });

    // Add colors
    items.forEach((item) => {
        // item.className = 'group' + groups.indexOf(groups.find((group) => group.id === item.group) ?? groups[0]);
        item.style = `background-color: ${
            colors[groups.indexOf(groups.find((group) => group.id === item.group) ?? groups[0]) % colors.length]
        }`;
    });

    // For options
    let start = new Date();
    items.forEach((item) => {
        if (item.start < start) {
            start = item.start;
        }
    });
    let end = new Date();
    items.forEach((item) => {
        if (item.end > end) {
            end = item.end;
        }
    });

    const options = {
        ...planTimelineOptions,
        start: new Date(start.valueOf() - 1000 * 60 * 60 * 24 * 60), // 60 days before start
        end: new Date(end.valueOf() + 1000 * 60 * 60 * 24 * 60 * 6), // 60 days after end
        // min is 60 days before start
        min: new Date(start.valueOf() - 1000 * 60 * 60 * 24 * 365),
        // max is 60 days after end
        max: new Date(end.valueOf() + 1000 * 60 * 60 * 24 * 365),
    };

    return { items, groups, options };
};

interface TransformedData {
    id: number;
    group: number | string;
    content: string;
    title: string;
    start: Date;
    end: Date;
    type?: 'box' | 'point' | 'range' | 'background';
    className?: string;
    style?: string;
}

interface PlanData {
    id: number;
    bygningid: number;
    bedstetid: string;
    beskrivelse: string;
    bygninglokation: string;
    kategorisys: string;
    stack_kategori: string;
    aprioritet: number;
    stack_aprioritet: string;
    tprioritet: number;
    stack_tprioritet: string;
    stack_kontoniveau1: string;
    stack_kontoniveau2: string;
    stack_kontoniveau3: string;
    stack_delsystem: string;
}

const stackMap = {
    bygning: 'bygningid',
    kategori: 'kategorisys',
    aprioritet: 'aprioritet',
    tprioritet: 'tprioritet',
    kontoniveau3: 'kontonummer3',
};

const stackContent = {
    [stackMap.bygning]: 'bygninglokation',
    [stackMap.kategori]: 'stack_kategori',
    [stackMap.aprioritet]: 'stack_aprioritet',
    [stackMap.tprioritet]: 'stack_tprioritet',
    [stackMap.kontoniveau3]: 'stack_kontoniveau3',
};
