import './content/custom/general.css';
import './content/custom/olBuildingLayersPopup.css';
import './content/custom/olCostumLayerControl.css';
import './content/custom/olCostumDrawControl.css';
import './content/custom/olCostumWktControl.css';
import './content/custom/olCostumSqlControl.css';
import './content/custom/olCostumBuildingControl.css';
import './content/custom/olSearchDKControl.css';
import './caretaker-map.css';

import { CSSProperties, useCallback, useLayoutEffect, useRef } from 'react';
import { initMap, setCenter, setControls, setLayers } from './src/index';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import useDictionary, { useDictionaryKeys } from '../../shared/hooks/redux-use-dictionary';

import BaseEvent from 'ol/events/Event';
import { BaseIconId } from './src/SVG';
import Button from '@mui/material/Button/Button';
import CTLayer from './src/CTLayer';
import { CenterCenterBox } from '../mui/styled-mui';
import { ControlNames } from './interfaces';
import { DragBoxEvent } from 'ol/interaction/DragBox';
import { DrawEvent } from 'ol/interaction/Draw';
import { IDictionary } from '../../shared/utils/types';
import { ModifyEvent } from 'ol/interaction/Modify';
import { OverlayKeys } from './src/react-components/clickable-features-overlays';
import PluggableMap from 'ol/PluggableMap';
import ReportDriftErrorControl from './src/react-controls/report-drift-error/control';
import { SelectEvent } from 'ol/interaction/Select';
import Stack from '@mui/material/Stack/Stack';
import { StyleLike } from 'ol/style/Style';
import Typography from '@mui/material/Typography/Typography';
import UtilsArray from '../../shared/utils/utils-array';
import _ from 'lodash';
import { shapeTypes } from './src/EditingControl';
import { LoadingStrategy } from 'ol/source/Vector';

//#region Props & Interfaces

export interface IMapProps {
    id: string; // Bruges til at differentierer mellem kort. Der er ikke et unik tjek, men den skal helst være unik
    displayZoom?: boolean;
    centerOnUser?: boolean;
    centerEnhed?: number;
    controls?: IMapControl[];
    layers?: IMapLayer[];
    baseLayers?: { name: string }[];
    onBeforeInitMap?(): void;
    onAfterInitMap?(): void;
    onComponentUnmount?(): void;

    // Extra options
    allowPopup?: boolean;
}

export interface IMapControl {
    name:
    | 'lmenu'
    | 'wkt'
    | 'search'
    | 'dev'
    | 'ipad'
    | 'gps'
    | 'report-error'
    | 'search-features'
    | 'service-map'
    | ControlNames.AddAddress
    | ControlNames.Overview;
    /** Disables GUI for the control. Not implemented everywhere */
    noGui?: boolean;
}

type InteractionEventType = 'propertychange' | 'change:active' | 'change' | 'error'; // (change | error) from EventTypes
type InteractionEventCallback = (event: BaseEvent | Event) => unknown;
type InteractionEventRecord = Record<InteractionEventType, InteractionEventCallback>;

/**
 * Extra data that can be attached to an interaction event
 */
export interface InteractionEventExtra {
    /** The layer interacted with */
    layer: CTLayer;
}

/**
 * Options for configuring the map layers
 *
 * Either url has to be set, or both view and geometry column.
 *
 * URL for pins, view and geometry for other layers vurrently. This will be changed in the future
 *
 * Both options currently exist for backwards compatibility
 */
export interface IMapLayer {
    /** The layer type */
    type: 'DEL' | 'ENH' | 'BYG' | 'LOK' | 'PIN' | 'POLY';

    /** Zoom lower bound, for when features should be displayed */
    minZoom?: number;

    /** Zoom upper bound, for when features should be displayed */
    maxZoom?: number;

    /**
     * Displayname shown in the layer menu
     *
     * Has to be unique
     */
    displayName: string; // Unique

    /**
     * The icon to show in the layer menu
     */
    baseIconId?: BaseIconId;

    /**
     * The url to fetch geometry from
     */
    url?: string;

    /**
     * A key indicating which overlay to use when a feature is clicked
     */
    overlayKey?: OverlayKeys;

    /**
     * If true, features will cluster
     * @default false
     */
    isCluster?: boolean;

    /** 
     * Strategy for loading features
     * - {@link all} will load all features at once
     * - {@link bbox} will only load features in the current view, continuously loading more as the view changes
     * For other strategies, see {@link https://openlayers.org/en/latest/apidoc/module-ol_loadingstrategy.html}
     * @default 'all'
     */
    loadingStrategy?: LoadingStrategy;

    /**
     * Options for clustering. Only checked if isCluster is true
     */
    clusterOptions?: {
        /**
         * Distance in pixels between features in cluster
         * @default 40
         */
        distance?: number;

        /**
         * Min distance between clusters. This may move the cluster off-center from the underlying features
         * @default 0
         */
        minDistance?: number;
    };

    /**
     * Style that will be applied to the features geometry
     */
    style?: 'auto' | (() => StyleLike);

    readOnly?: 0 | 1 | boolean;

    /**
     * Type to show right editing tools and svg
     */
    shapeType?: shapeTypes;

    /** Color to show layer in overlay */
    color?: string;

    /** hide overlapping features
     * @default false
     */
    declutter?: boolean;

    /** short string to write in the svg if undefined, the svg will just be the shapeType */
    svgString?: string;

    /** boolean indicating whether the layer has a schema
     * @default false
     */
    skema?: boolean;

    events?: {
        interactions?: {
            // These are manually written types, copied from the ol source code, and may not be complete
            draw?:
            | InteractionEventRecord
            | Partial<
                Record<
                    'drawabort' | 'drawend' | 'drawstart',
                    (event: DrawEvent, extra: InteractionEventExtra) => unknown
                >
            >;
            modify?:
            | InteractionEventRecord
            | Partial<
                Record<'modifyend' | 'modifystart', (event: ModifyEvent, extra: InteractionEventExtra) => unknown>
            >;
            select?:
            | InteractionEventRecord
            | Partial<Record<'select', (event: SelectEvent, extra: InteractionEventExtra) => unknown>>;
            deleteSelect?:
            | InteractionEventRecord
            | Partial<Record<'select', (event: SelectEvent, extra: InteractionEventExtra) => unknown>>;
            dragBox?:
            | InteractionEventRecord
            | Partial<
                Record<
                    'boxcancel' | 'boxdrag' | 'boxend' | 'boxstart',
                    (event: DragBoxEvent, extra: InteractionEventExtra) => unknown
                >
            >;
        };
    };

    /**
     * The max resolution for the bbox strategy to fetch features
     */
    maxResForBboxFetch?: number;

    /**
     * If using bbox strategy, should the layer be updated when the view changes. 
     * Default in map-screen is false
     */
    bboxUpdateOnViewChange?: boolean;

    //#region Deprecated

    /**
     * @deprecated use url instead of these options
     */
    view?: string;

    /**
     * @deprecated use url instead of these options
     */
    geometryColumn?: string;

    /**
     * @deprecated use url instead of these options
     */
    enhId?: number;

    /**
     * @deprecated use url instead of these options
     */
    bygId?: number;

    /**
     * @deprecated use url instead of these options
     */
    lokId?: number;

    /**
     * @deprecated use url instead of these options
     */
    delId?: number;

    /**
     * @deprecated use url instead of these options
     */
    jouId?: number;

    //#endregion Deprecated

    /**
     * @todo implement status
     */
    status?: string; // Status til OK-tanke
}

//#endregion
//#region Special Layers setup

/**  */
const addSpecialLayers = (controls: IMapControl[], layers: IMapLayer[]) => {
    const allLayers = _.cloneDeep(layers);
    const controlNames = controls.map((c) => c.name);
    const layerNames = layers.map((l) => l.displayName);

    /** Adds a layer if the control name is given, and layer doesn't already exist */
    const addLayer = (controlName: IMapControl['name'], layerName: string, layer: IMapLayer) => {
        if (controlNames.includes(controlName)) {
            console.assert(!layerNames.includes(layerName));
            allLayers.unshift(layer);
        }
    };

    addLayer('report-error', ReportDriftErrorControl.displayName, predefinedIMapLayers.reportDriftErrorLayer);

    return allLayers;
};

//#region Layers

type pdimlKeys = 'reportDriftErrorLayer';
export const predefinedIMapLayers: IDictionary<IMapLayer, pdimlKeys> = {
    reportDriftErrorLayer: {
        type: 'PIN',
        // url: `${config.NODE_TEST_API}/Map/Fejl`,
        // url: `${config.NODE_TEST_API}/Helpdesk`,
        url: `/Helpdesk`,
        // url: '/Map/Fejl',
        displayName: ReportDriftErrorControl.displayName,
        baseIconId: BaseIconId.Enhed,
        overlayKey: OverlayKeys.reportError,
        minZoom: 0,
        maxZoom: 9999,
        isCluster: true,
        clusterOptions: {
            distance: 0,
        },
    },
};

//#endregion Layers

//#endregion Special Layers setup

/**
 * A component used to display an openlayers map
 *
 * @returns a div with a bounding box equal to the one the map will be displayed in
 */
const CaretakerMap = ({
    id = '',
    displayZoom = false,
    centerOnUser = true,
    centerEnhed,
    controls = [],
    layers = [],
    baseLayers,
    onBeforeInitMap,
    onAfterInitMap,
    onComponentUnmount,
    allowPopup = false,
}: IMapProps) => {
    const mProps = useRef({
        id,
        displayZoom,
        centerOnUser,
        centerEnhed,
        controls,
        layers,
        baseLayers,
        onBeforeInitMap,
        onAfterInitMap,
        onComponentUnmount,
        allowPopup,
    });
    const [currentMapKey, setCurrentMapKey] = useDictionary(useDictionaryKeys.MapActive, '');
    const [lastMapKey, setLastMapKey] = useDictionary(useDictionaryKeys.MapLast, '');
    const [, setMapCss] = useDictionary(useDictionaryKeys.MapCss, '{}');
    const ref = useRef<HTMLDivElement>(null);
    const mapRef = useRef<{ map: PluggableMap | undefined }>({ map: undefined });
    const { isLandscape } = useMobileOrientation();

    //#region Show / Hide Map

    /** Recalculates the map containers size */
    const handlePosChange = useCallback(() => {
        let obj = ref.current;
        if (obj == null) return;

        const height = obj.offsetHeight,
            width = obj.offsetWidth;
        let top = 0,
            left = 0;
        if (obj.offsetParent) {
            do {
                left += obj.offsetLeft;
                top += obj.offsetTop;
                // eslint-disable-next-line no-cond-assign
            } while ((obj = obj.offsetParent as HTMLDivElement));
        }

        const css: CSSProperties = {
            top: `${top}px`,
            left: `${left}px`,
            height: `${height}px`,
            width: `${width}px`,
        };

        setMapCss(JSON.stringify(css));
    }, [setMapCss]);

    // Cleanup when navigating away
    useLayoutEffect(() => {
        const props = mProps.current;
        setCurrentMapKey(props.id);
        handlePosChange();
        return () => {
            setCurrentMapKey('');
            setLastMapKey(props.id);
            setMapCss('{}');
            props.onComponentUnmount?.();
        };
    }, [handlePosChange, setCurrentMapKey, setLastMapKey, setMapCss]);

    // Resize map when the root size changes.
    // Size is assumed to be constant on mobile devices
    useLayoutEffect(() => {
        if (isMobile) return;

        const observer = new ResizeObserver(handlePosChange);
        ref.current && observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, handlePosChange]);

    // Resize on change between portrait and landscape
    useLayoutEffect(() => {
        mapRef.current.map?.updateSize();
        handlePosChange();
    }, [handlePosChange, isLandscape]);

    //#endregion Show / Hide Map
    //#region init

    // Init map / Switch to this map when it mounts
    useLayoutEffect(() => {
        const props = mProps.current;

        const shouldInit = () => {
            return currentMapKey === props.id && lastMapKey !== props.id;
        };

        const init = () => {
            // Tjek at lag er unikke
            console.assert(UtilsArray.isUnique(props.layers?.map((l) => l.displayName)));
            const layers = addSpecialLayers(props.controls!, props.layers!);

            props.onBeforeInitMap?.();
            setCenter(props.centerOnUser!, props.centerEnhed);
            setControls(props.controls!);
            setLayers(layers, baseLayers);

            mapRef.current.map = initMap();

            props.onAfterInitMap?.();
        };

        if (shouldInit()) init();
    }, [baseLayers, currentMapKey, lastMapKey]);

    //#endregion init

    return (
        <CenterCenterBox ref={ref} id='map-react-component'>
            <Stack spacing={2}>
                <Typography>Noget gik galt</Typography>
                <Button onClick={() => window.location.reload()}>Prøv igen</Button>
            </Stack>
        </CenterCenterBox>
    );
};

export default CaretakerMap;
