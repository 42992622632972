import { UserPermissions } from '../../components/restricted';
import { fromRestrictedRouteObjects } from '../../components/restricted/routing';
import { lazy } from 'react';
import { redirect } from 'react-router-dom';

const DashboardScreen = lazy(() => import('./dashboard-screen'));
const DashboardEditScreen = lazy(() => import('./dashboard-edit-screen'));

export const DashboardRouterObjects = fromRestrictedRouteObjects([
    {
        index: true,
        loader: ({ params }) => redirect(`/${params.id!}/Dashboard/dashboard1`),
    },
    {
        path: ':dashboardId',
        element: <DashboardScreen />,
        permissions: UserPermissions.Dashboard
    },
    {
        path: ':dashboardId/edit',
        element: <DashboardEditScreen />,
        permissions: UserPermissions.Dashboard_Create,
    },
]);
